import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {StartDvrRecordPayload} from './interfaces/start-dvr-record-payload.interface';
import {DeleteDvrRecordPayload} from './interfaces/delete.dvr-record.payload.interface';
import {DvrRecord} from './dvr.model';

@Injectable({
  providedIn: 'root'
})
export class DvrService {

  constructor(
    private readonly apiService: ApiService,
  ) {
  }

  public getRecords(): Observable<DvrRecord[]> {
    return this.apiService.get(`/dvr`);
  }

  public startRecord(payload: StartDvrRecordPayload): Observable<DvrRecord> {
    return this.apiService.post(`/dvr/start/`, payload);
  }

  public stopRecord(recordId: string): Observable<DvrRecord> {
    return this.apiService.get(`/dvr/stop/${recordId}`);
  }

  public deleteRecord(data: DeleteDvrRecordPayload): Observable<DvrRecord> {
    return this.apiService.delete(`/dvr/${data.recordId}`, {removeFile: data.shouldRemoveFile});
  }

  public getDownloadRecordLink(recordId: string): Observable<string> {
    const options = {
      responseType: 'text'
    }
    return this.apiService.get(`/dvr/download/${recordId}`, {}, options);
  }

  public getRecordLink(recordId: string): Observable<string> {
    const options = {
      responseType: 'text'
    }
    return this.apiService.get(`/dvr/link/${recordId}`, {}, options);
  }
}
