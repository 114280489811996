import { createReducer, on } from '@ngrx/store';
import * as UpdateClipActions from '../actions/update-clip.actions';
import {Clip} from '../../../services/api/clips/clip.model';

export const updateClipFeatureKey = 'updateClip';

export interface State {
  record: Clip;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(UpdateClipActions.updateClip, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(UpdateClipActions.updateClipSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(UpdateClipActions.updateClipFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(UpdateClipActions.clearUpdateClipState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  }))
);
