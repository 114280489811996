import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import * as ClipsActions from '../actions/clips.actions';
import {ClipsService} from '../../../services/api/clips/clips.service';
import {Clip} from '../../../services/api/clips/clip.model';


@Injectable()
export class ClipsEffects {

  // noinspection JSUnusedGlobalSymbols
  loadClips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClipsActions.loadClips),
      concatMap(() =>
        this.clipsService.getClips()
          .pipe(
            map((data: Clip[]) => ClipsActions.loadClipsSuccess({data})),
            catchError((error: any) => of(ClipsActions.loadClipsFailure({error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly clipsService: ClipsService
  ) {
  }

}
