<div [formGroup]="entityControl" autocomplete="off">
  <mat-form-field>
    <mat-label>Stream Name</mat-label>
    <input #trigger="matAutocompleteTrigger" type="text" matInput formControlName="currentEntity" autocomplete="off"
           [matAutocomplete]="auto" (input)="onTypingInput()">
    <button *ngIf="entityControl.value" class="clear-button"
            matSuffix mat-icon-button aria-label="Clear"
            (click)="trigger.openPanel(); onInputCancelClick($event);">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectInput()">
      <mat-option
        (mouseenter)="mouseEnter.emit()"
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="entityControl.invalid &&
                    (entityControl.dirty || entityControl.touched)">
      Please choose value from the list
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="showInputDetails">
    <mat-label>Cluster</mat-label>
    <mat-select formControlName="clusterId" (selectionChange)="onChangeCluster()">
      <mat-option (mouseenter)="mouseEnter.emit()"
                  *ngFor="let cluster of configurationClusters" [value]="cluster.id">
        {{cluster.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="showInputDetails">
    <mat-label>Type</mat-label>
    <mat-select formControlName="role" (selectionChange)="onChangeCluster()">
      <mat-option (mouseenter)="mouseEnter.emit()" [value]="'primary'">Primary</mat-option>
      <mat-option (mouseenter)="mouseEnter.emit()"
                  *ngIf="currentClusterInstances.length > 1" [value]="'backup'">Backup</mat-option>
    </mat-select>
  </mat-form-field>
</div>

