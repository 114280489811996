import { createReducer, on } from '@ngrx/store';
import * as UpdeteGridActions from '../actions/update-grid.actions';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';

export const updateGridFeatureKey = 'updateGrid';

export interface State {
  record: Multiview;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(UpdeteGridActions.updateGrid, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(UpdeteGridActions.updateGridSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(UpdeteGridActions.updateGridFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(UpdeteGridActions.clearUpdateGridState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

