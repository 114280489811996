import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthScheme, AuthSchemeName} from '../../../services/api/destinations/auth-schemes/auth-scheme.model';
import {DestinationRtmpTargetConfiguration} from '../../../services/api/destinations/destination.model';
import {RtmpProtocol} from '../../../services/api/stream-configurations/stream-configuration.model';

export interface DestinationRtmpPushForm {
  protocol: FormControl<RtmpProtocol>;
  streamUrl: FormControl<string>;
  port: FormControl<number>;
  streamKey: FormControl<string>;
  authScheme: FormControl<AuthSchemeName>;
  username: FormControl<string>;
  password: FormControl<string>;
  akamaiStreamId: FormControl<number>;
}

@Component({
  selector: 'app-destination-rtmp-push-form',
  templateUrl: './destination-rtmp-push-form.component.html',
  styleUrls: ['./destination-rtmp-push-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationRtmpPushFormComponent implements OnChanges, OnDestroy {
  @Input() destinationControl: FormGroup<DestinationRtmpPushForm>;
  @Input() destination: DestinationRtmpTargetConfiguration;
  @Input() authSchemes: AuthScheme[];
  public selectedScheme: AuthScheme;

  private getDefaultAuthScheme(): AuthScheme {
    return this.authSchemes.find((item: AuthScheme): boolean => item.name === 'NONE');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes['destinationControl'] || changes['authSchemes']) && this.destinationControl && this.authSchemes) {
      this.selectedScheme = this.getDefaultAuthScheme();

      if (this.destination) {
        if (this.destination.authentication && this.destination.authentication.scheme) {
          const authScheme: AuthScheme | undefined = this.authSchemes
            .find((item: AuthScheme): boolean => item.name === this.destination.authentication.scheme);
          if (authScheme !== undefined) {
            this.selectedScheme = authScheme;
          }
        }

        this.createControls(this.destination);
      } else {
        this.createControls();
      }
    }
  }

  private createControls(destination: DestinationRtmpTargetConfiguration = {
    connectionType: 'RTMP',
    address: null,
    port: 1935,
    application: null,
    streamKey: null,
    authentication: {
      scheme: 'NONE',
      username: null,
      password: null,
      akamaiStreamId: null
    }
  }): void {
    let streamUrl: string | null = null;
    if (destination.address && destination.application) {
      streamUrl = `${destination.address}/${destination.application}`;
    }

    const authScheme: AuthSchemeName | undefined = this.selectedScheme.name;
    this.destinationControl.addControl('protocol', new FormControl(destination.connectionType, Validators.required));
    this.destinationControl.addControl('streamUrl', new FormControl(streamUrl, Validators.required));
    this.destinationControl.addControl('port', new FormControl(destination.port, Validators.required));
    this.destinationControl.addControl('streamKey', new FormControl(destination.streamKey, Validators.required));
    this.destinationControl.addControl('authScheme', new FormControl(authScheme, Validators.required));

    if (this.selectedScheme.auth) {
      this.destinationControl.addControl('username', new FormControl(destination.authentication.username, Validators.required));
      this.destinationControl.addControl('password', new FormControl(destination.authentication.password, Validators.required));
    }

    if (this.selectedScheme.streamId) {
      this.destinationControl.addControl('akamaiStreamId', new FormControl(destination.authentication.akamaiStreamId, Validators.required));
    }
  }

  public onPasteRtmpStreamUrl(): void {
    setTimeout(() => {
      let streamKey = '';
      let streamUrlValue = this.destinationControl.get('streamUrl').value;

      if (streamUrlValue.indexOf(':/') !== -1) {
        const end: number = streamUrlValue.indexOf(':/');
        const protocol: RtmpProtocol = streamUrlValue.slice(0, end).toUpperCase() as RtmpProtocol;
        this.destinationControl.controls.protocol.setValue(protocol);
        this.onChangeProtocol();

        streamUrlValue = streamUrlValue.slice(end + 3);
      }

      if (streamUrlValue.indexOf(':') !== -1) {
        const start: number = streamUrlValue.indexOf(':');
        const end: number = streamUrlValue.indexOf('/');
        const isValidRange: boolean = start < end;

        if (isValidRange) {
          const port = streamUrlValue.slice(streamUrlValue.indexOf(':') + 1, streamUrlValue.indexOf('/'));
          this.destinationControl.get('port').setValue(Number(port));
          streamUrlValue = streamUrlValue.slice(0, streamUrlValue.indexOf(':')) + streamUrlValue.slice(streamUrlValue.indexOf('/'));
        }
      } else {
        this.onChangeProtocol();
      }

      /* Trick for URLs like this:
      * rtmp://vvcr.live:1935/live/streams/stream-key
      */
      const slashesCount = streamUrlValue.split('/').length - 1;
      if (slashesCount > 1) {
        const lastSlashIndex: number = streamUrlValue.lastIndexOf('/');
        const prevSlashIndex: number = streamUrlValue.slice(0, lastSlashIndex).lastIndexOf('/');
        const dataAfterLastSlash: string = streamUrlValue.slice(lastSlashIndex + 1);
        if (dataAfterLastSlash.length > 0) {
          /* Examples:
            "rtmp://vvcr.live:1935/live/stream-key"
            "rtmp://vvcr.live:1935/live/streams/stream-key"
          */
          streamKey = dataAfterLastSlash;
          streamUrlValue = streamUrlValue.slice(0, lastSlashIndex);
        } else if (slashesCount > 2) {
          /* Example:
            "rtmp://vvcr.live:1935/live/stream-key/"
            "rtmp://vvcr.live:1935/live/streams/stream-key/"
          */
          streamKey = streamUrlValue.slice(prevSlashIndex + 1, lastSlashIndex);
          streamUrlValue = streamUrlValue.slice(0, prevSlashIndex);
        }

        this.destinationControl.controls.streamKey.setValue(streamKey);
      }

      this.destinationControl.controls.streamUrl.setValue(streamUrlValue);
    });
  }

  public onChangeProtocol(): void {
    const protocol: RtmpProtocol = this.destinationControl.controls.protocol.value;
    if (protocol === 'RTMP') {
      this.destinationControl.controls.port.setValue(1935);
    } else {
      this.destinationControl.controls.port.setValue(443);
    }
  }

  public onChangeScheme(): void {
    const schemeName: AuthSchemeName = this.destinationControl.controls.authScheme.value;
    const scheme: AuthScheme | undefined = this.authSchemes.find((item: AuthScheme): boolean => item.name === schemeName);
    if (scheme !== undefined) {
      this.selectedScheme = scheme;
    }

    if (this.selectedScheme.auth) {
      this.destinationControl.addControl('username', new FormControl(null, Validators.required));
      this.destinationControl.addControl('password', new FormControl(null, Validators.required));
    } else {
      this.destinationControl.removeControl('username');
      this.destinationControl.removeControl('password');
    }

    if (this.selectedScheme.streamId) {
      this.destinationControl.addControl('akamaiStreamId', new FormControl(null, Validators.required));
    } else {
      this.destinationControl.removeControl('akamaiStreamId');
    }
  }

  public ngOnDestroy(): void {
    this.removeControls();
  }

  private removeControls() {
    this.destinationControl.removeControl('streamUrl');
    this.destinationControl.removeControl('streamKey');
    this.destinationControl.removeControl('authScheme');
    this.destinationControl.removeControl('username');
    this.destinationControl.removeControl('password');
    this.destinationControl.removeControl('akamaiStreamId');
  }

}
