import {createAction, props} from '@ngrx/store';
import {Clip, ClipRequest} from '../../../services/api/clips/clip.model';

export const updateClip = createAction(
  '[UpdateClip] Update Clip',
  props<{ id: string, data: ClipRequest }>()
);

export const updateClipSuccess = createAction(
  '[UpdateClip] Update Clip Success',
  props<{ data: Clip }>()
);

export const updateClipFailure = createAction(
  '[UpdateClip] Update Clip Failure',
  props<{ error: any }>()
);

export const clearUpdateClipState = createAction(
  '[UpdateClip] Clear Update Clip State'
);
