import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromDestinations from '../reducers/destinations.reducer';
import {State} from '../reducers/destinations.reducer';
import {Destination} from '../../../services/api/destinations/destination.model';

export const selectDestinationsState = createFeatureSelector<fromDestinations.State>(
  fromDestinations.destinationsFeatureKey
);

export const getDestinationsRecord = createSelector(
  selectDestinationsState,
  (state: State): Destination[] => state.record
);

export const getDestinationsLoaded = createSelector(
  selectDestinationsState,
  (state: State): boolean => state.loaded
);

export const getDestinationsLoading = createSelector(
  selectDestinationsState,
  (state: State): boolean => state.loading
);

export const getDestinationsError = createSelector(
  selectDestinationsState,
  (state: State): any => state.error
);
