import { createReducer, on } from '@ngrx/store';
import * as DestinationsActions from '../actions/destinations.actions';
import { Utils } from '../../../services/Utils';
import {Destination} from '../../../services/api/destinations/destination.model';

export const destinationsFeatureKey = 'destinations';

export interface State {
  record: Destination[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(DestinationsActions.loadDestinations, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(DestinationsActions.loadDestinationsSuccess, (state, action) => ({
    record: Utils.naturalSortArrayByKey(action.data, 'name'),
    error: null,
    loaded: true,
    loading: false,
  })),
  on(DestinationsActions.loadDestinationsFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(DestinationsActions.clearDestinationsState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  })),
);
