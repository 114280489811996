import { createReducer, on } from '@ngrx/store';
import * as StartRecordActions from '../actions/start-record.actions';
import {DvrRecord} from '../../../services/api/dvr/dvr.model';

export const startRecordFeatureKey = 'startRecord';

export interface State {
  record: DvrRecord;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(StartRecordActions.startRecord, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(StartRecordActions.startRecordSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(StartRecordActions.startRecordFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(StartRecordActions.clearStartRecordState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

