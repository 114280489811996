import { createAction, props } from '@ngrx/store';
import {DvrRecord} from '../../../services/api/dvr/dvr.model';

export const loadRecords = createAction(
  '[Records] Load Records'
);

export const loadRecordsSuccess = createAction(
  '[Records] Load Records Success',
  props<{ data: DvrRecord[] }>()
);

export const loadRecordsFailure = createAction(
  '[Records] Load Records Failure',
  props<{ error: any }>()
);

export const clearRecordsState = createAction(
  '[Records] clear Records State',
);
