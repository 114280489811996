import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppLayoutComponent} from './app-layout.component';
import {AuthGuard} from './guards/auth.guard';
import {PermissionGuard} from './guards/permission.guard';
import {OnlyAdminGuard} from './guards/only-admin.guard';
import {RequiredClustersGuard} from './guards/required-clusters.guard';
import {ImgLoaderGuard} from './guards/img-loader.guard';


const routes: Routes = [
  {
    path: '', component: AppLayoutComponent, canActivate: [AuthGuard, ImgLoaderGuard], children: [
      {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'clusters', data: {feature: 'clusters', permissionMode: 'read'},
        loadChildren: () => import('./pages/clusters/clusters.module').then(m => m.ClustersModule)
      },
      {
        path: 'clusters/new', data: {mode: 'new', feature: 'clusters', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-cluster/manage-cluster.module').then(m => m.ManageClusterModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'streams', data: {feature: 'streams', permissionMode: 'read'},
        loadChildren: () => import('./pages/inputs/inputs.module').then(m => m.InputsModule)
      },
      {
        path: 'streams/new', data: {mode: 'new', feature: 'streams', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-input/manage-input.module').then(m => m.ManageInputModule),
        canActivate: [PermissionGuard, RequiredClustersGuard]
      },
      {
        path: 'streams/new/:id', data: {mode: 'new-duplicate', feature: 'streams', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-input/manage-input.module').then(m => m.ManageInputModule),
        canActivate: [PermissionGuard, RequiredClustersGuard]
      },
      {
        path: 'streams/edit/:id', data: {mode: 'edit', feature: 'streams', permissionMode: 'edit'},
        loadChildren: () => import('./pages/manage-input/manage-input.module').then(m => m.ManageInputModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'destinations', data: {feature: 'destinations', permissionMode: 'read'},
        loadChildren: () => import('./pages/destinations/destinations.module').then(m => m.DestinationsModule),
        canActivate: []
      },
      {
        path: 'destinations/new', data: {mode: 'new', feature: 'destinations', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-destination/manage-destination.module').then(m => m.ManageDestinationModule),
        canActivate: [PermissionGuard, RequiredClustersGuard]
      },
      {
        path: 'destinations/new/:id', data: {mode: 'new-duplicate', feature: 'destinations', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-destination/manage-destination.module').then(m => m.ManageDestinationModule),
        canActivate: [PermissionGuard, RequiredClustersGuard]
      },
      {
        path: 'destinations/edit/:id', data: {mode: 'edit', feature: 'destinations', permissionMode: 'edit'},
        loadChildren: () => import('./pages/manage-destination/manage-destination.module').then(m => m.ManageDestinationModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'multiview', data: {feature: 'grids', permissionMode: 'read'},
        loadChildren: () => import('./pages/grids/grids.module').then(m => m.GridsModule)
      },
      {
        path: 'multiview/new', data: {mode: 'new', feature: 'grids', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-multiview/manage-multiview.module').then(m => m.ManageMultiviewModule),
        canActivate: [PermissionGuard, RequiredClustersGuard]
      },
      {
        path: 'multiview/edit/:id', data: {mode: 'edit', feature: 'grids', permissionMode: 'edit'},
        loadChildren: () => import('./pages/manage-multiview/manage-multiview.module').then(m => m.ManageMultiviewModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'dvr', data: {feature: 'dvr', permissionMode: 'read'},
        loadChildren: () => import('./pages/dvr/dvr.module').then(m => m.DvrModule)
      },
      {
        path: 'organizations', data: {access: [10]},
        loadChildren: () => import('./pages/organizations/organizations.module').then(m => m.OrganizationsModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organizations/new', data: {mode: 'new', access: [10]},
        loadChildren: () => import('./pages/manage-organizations/manage-organizations.module').then(m => m.ManageOrganizationsModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organizations/edit/:id', data: {mode: 'edit', access: [5, 10]},
        loadChildren: () => import('./pages/manage-organizations/manage-organizations.module').then(m => m.ManageOrganizationsModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organization', data: {access: [5, 10]},
        loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organization/edit/:id', data: {mode: 'edit', access: [5, 10]},
        loadChildren: () => import('./pages/manage-organizations/manage-organizations.module').then(m => m.ManageOrganizationsModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organization/licenses-history', data: {mode: 'edit', access: [5, 10]},
        loadChildren: () => import('./pages/transcoding-licenses-history/transcoding-licenses-history.module')
          .then(module => module.TranscodingLicensesHistoryModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organization/users/new', data: {mode: 'new', access: [5, 10]},
        loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organizations/users/new', data: {mode: 'new', access: [5, 10]},
        loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organization/users/edit/:id', data: {mode: 'edit', access: [5, 10]},
        loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organizations/users/edit/:id', data: {mode: 'edit', access: [5, 10]},
        loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'clips',
        loadChildren: () => import('./pages/clips/clips.module').then(m => m.ClipsModule)
      },
      {
        path: 'clips/new',
        data: {mode: 'new', feature: 'clips', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-clip/manage-clip.module')
          .then(module => module.ManageClipModule),
        canActivate: [RequiredClustersGuard, PermissionGuard]
      },
      {
        path: 'clips/edit/:id', data: {mode: 'edit', feature: 'clips', permissionMode: 'edit'},
        loadChildren: () => import('./pages/manage-clip/manage-clip.module').then(m => m.ManageClipModule),
        canActivate: [PermissionGuard, PermissionGuard]
      },
      {
        path: 'kairos', data: {feature: 'kairos', permissionMode: 'read'},
        loadChildren: () => import('./pages/kairos/kairos.module').then(module => module.KairosModule)
      },
      {
        path: 'device-management-systems', data: {feature: 'device-management-systems', permissionMode: 'read'},
        loadChildren: () => import('./pages/kairos-device-management-system/kairos-device-management-system.module')
          .then(module => module.KairosDeviceManagementSystemModule)
      },
      {
        path: 'production', data: {feature: 'production', permissionMode: 'read'},
        loadChildren: () => import('./pages/production/production.module').then(m => m.ProductionModule)
      },
      {
        path: 'production/new', data: {mode: 'new', feature: 'production', permissionMode: 'create'},
        loadChildren: () => import('./pages/manage-production/manage-production.module').then(m => m.ManageProductionModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'production/edit/:id', data: {mode: 'edit', feature: 'production', permissionMode: 'edit'},
        loadChildren: () => import('./pages/manage-production/manage-production.module').then(m => m.ManageProductionModule),
        canActivate: [PermissionGuard]
      },
      {
        path: 'payment', data: {access: [5]},
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'usage', data: {access: [5, 10]},
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [OnlyAdminGuard]
      },
      {path: 'no-access', loadChildren: () => import('./pages/no-access/no-access.module').then(m => m.NoAccessModule)}
    ]
  },
  {path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)},
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {path: 'signup/:token', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule)},
  {path: 'multiview/:id', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule)},
  {path: 'multiview/:dns/:shortName', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule)},
  {path: 'grids/:id', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule)},
  {path: 'grids/:dns/:shortName', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule)},
  {
    path: 'choose-organization',
    loadChildren: () => import('./pages/choose-organization/choose-organization.module').then(m => m.ChooseOrganizationModule)
  },
  {
    path: 'invite/:token',
    loadChildren: () => import('./pages/activate-invitation/activate-invitation.module').then(m => m.ActivateInvitationModule)
  },
  {path: '**', redirectTo: 'clusters'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
