import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import * as RecordsActions from '../actions/records.actions';
import {DvrService} from '../../../services/api/dvr/dvr.service';
import {DvrRecord} from '../../../services/api/dvr/dvr.model';


@Injectable()
export class RecordsEffects {

  // noinspection JSUnusedGlobalSymbols
  loadRecords$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(RecordsActions.loadRecords),
        concatMap(() =>
          this.dvrService.getRecords().pipe(
            map((data: DvrRecord[]) => RecordsActions.loadRecordsSuccess({data})),
            catchError((error: any) => of(RecordsActions.loadRecordsFailure({error}))))
        )
      );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly dvrService: DvrService
  ) {
  }

}
