import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {loadNdiSignals, loadNdiSignalsFailure, loadNdiSignalsSuccess} from './ndi-singnals.actions';
import {NdiSignal} from '../../services/api/ndi-signals/ndi-input.model';
import {NdiSignalsService} from '../../services/api/ndi-signals/ndi-signals.service';



@Injectable()
export class NdiSignalsEffects {

  // noinspection JSUnusedGlobalSymbols
  public loadNdiSignals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadNdiSignals),
      concatMap((payload) =>
        this.service.getAll(payload.data)
          .pipe(
            map((signals: NdiSignal[]) => loadNdiSignalsSuccess({data: signals})),
            catchError(error => of(loadNdiSignalsFailure({error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly service: NdiSignalsService
  ) {
  }

}
