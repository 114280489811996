import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {DestinationUpdate} from './interfaces/destination.model';
import {Destination, DestinationRequest} from './destination.model';

@Injectable({
  providedIn: 'root'
})
export class DestinationsService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getDestinations(): Observable<Destination[]> {
    return this.apiService.get(`/destinations`);
  }

  public getDestination(destinationId: string): Observable<Destination> {
    return this.apiService.get(`/destinations/${destinationId}`);
  }

  public create(data: DestinationRequest): Observable<Destination> {
    return this.apiService.post(`/destinations`, data);
  }

  public update(update: DestinationUpdate): Observable<any> {
    const id: string = update.id;
    const data: DestinationRequest = update.data;
    return this.apiService.put(`/destinations/${id}`, data);
  }

  public delete(destinationId: string): Observable<any> {
    return this.apiService.delete(`/destinations/${destinationId}`);
  }

  public startDestination(destinationId: string): Observable<any> {
    return this.apiService.get(`/destinations/resume/${destinationId}`);
  }

  public stopDestination(destinationId: string): Observable<any> {
    return this.apiService.get(`/destinations/stop/${destinationId}`);
  }
}
