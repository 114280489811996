import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import {Clip, ClipRequest} from './clip.model';

@Injectable({
  providedIn: 'root'
})
export class ClipsService {

  constructor(
    private readonly apiService: ApiService
  ) {}

  public getClips(): Observable<Clip[]> {
    return this.apiService.get(`/clips`);
  }

  public create(data: ClipRequest): Observable<Clip> {
    return this.apiService.post('/clips', data);
  }

  public update(id: string, data: ClipRequest): Observable<Clip> {
    return this.apiService.put(`/clips/${id}`, data);
  }

  public delete(id: string): Observable<Clip> {
    return this.apiService.delete(`/clips/${id}`);
  }

  public getClipSignedLink(id: string): Observable<string> {
    const options = {
      responseType: 'text'
    }
    return this.apiService.get(`/clips/link/${id}`, {}, options);
  }
}
