import { createAction, props } from '@ngrx/store';
import {Clip, ClipRequest} from '../../../services/api/clips/clip.model';

export const cutVideo = createAction(
  '[CutVideo] Cut Video',
  props<{ data: ClipRequest }>()
);

export const cutVideoSuccess = createAction(
  '[CutVideo] Cut Video Success',
  props<{ data: Clip }>()
);

export const cutVideoFailure = createAction(
  '[CutVideo] Cut Video Failure',
  props<{ error: any }>()
);

export const clearCutVideoState = createAction(
  '[CutVideo] clear Cut Video State',
);
