import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State} from './ndi-signals.reducer';
import {NdiSignal} from '../../services/api/ndi-signals/ndi-input.model';

export const getNdiSignalsFeatureKey = 'getNdiSignals';
export const featureSelector = createFeatureSelector<State>(getNdiSignalsFeatureKey);

export const getNdiSignalsRecord = createSelector(
  featureSelector,
  (state: State): NdiSignal[] => state.record
);

export const getNdiSignalsLoaded = createSelector(
  featureSelector,
  (state: State): boolean => state.loaded
);

export const getNdiSignalsLoading = createSelector(
  featureSelector,
  (state: State): boolean => state.loading
);

export const getNdiSignalsError = createSelector(
  featureSelector,
  (state: State): any => state.error
);
