import { createAction, props } from '@ngrx/store';
import {Clip} from '../../../services/api/clips/clip.model';

export const deleteClip = createAction(
  '[DeleteClip] Delete Clip',
  props<{ id: string }>()
);

export const deleteClipSuccess = createAction(
  '[DeleteClip] Delete Clip Success',
  props<{ data: Clip }>()
);

export const deleteClipFailure = createAction(
  '[DeleteClip] Delete Clip Failure',
  props<{ error: any }>()
);

export const clearDeleteClipState = createAction(
  '[DeleteClip] clear Cut Video State',
);
