import { createAction, props } from '@ngrx/store';
import {Multiview, MultiviewRequest} from '../../../services/api/multiviews/multiview-viewer.model';

export const createGrid = createAction(
  '[CreateGrid] Create Grid',
  props<{ data: MultiviewRequest }>()
);

export const createGridSuccess = createAction(
  '[CreateGrid] Create Grid Success',
  props<{ data: Multiview }>()
);

export const createGridFailure = createAction(
  '[CreateGrid] Create Grid Failure',
  props<{ error: any }>()
);

export const clearCreateGridState = createAction(
  '[CreateGrid] clear Create Grid State',
);
