import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Cluster, ClusterInstance} from '../../../services/api/clusters/cluster.model';
import {Instance} from '../../../models/instances.interface';
import {FormGroup} from '@angular/forms';
import {StreamConfigurationFormItem} from './interfaces/input-form.item.interface';
import {InstanceRole, Stream, StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {AddRecordingStreamForm} from '../../../pages/dvr/components/add-recording-modal/add-recording-modal.component';

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFormComponent implements OnChanges {
  @Input() inputControl: FormGroup<AddRecordingStreamForm>;
  @Input() streamConfigurations: StreamConfigurationFormItem[] | null = null;
  @Input() clusters: Cluster[] | null = null;

  public showInputDetails: boolean = false;
  public filteredConfigurations: Observable<StreamConfigurationFormItem[]>;
  public inputClusters: Cluster[] = [];
  public currentClusterInstances: Instance[] = [];
  public currentInstance: Instance | null = null;

  private getClustersByConfigurationId(configurationId: string): Cluster[] {
    const configuration: StreamConfiguration = this.streamConfigurations
      .find((item: StreamConfigurationFormItem): boolean => item.id === configurationId);
    const streams: Stream[] = configuration.streams;
    const instanceIds: string[] = streams.map((stream: Stream): string => stream.instanceId);
    return this.clusters
      .filter((cluster: Cluster): boolean =>
        cluster.instances.some((instance: ClusterInstance): boolean =>
          instanceIds.includes(instance.id))
      );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.streamConfigurations) {
      this.filteredConfigurations = this.inputControl.controls.configuration.valueChanges
        .pipe(
          startWith(''),
          map((value: any): string => typeof value === 'string' ? value : value.name),
          map((name: string): StreamConfigurationFormItem[] => this.filterInputsRecord(name))
        );
    }

    const configuration: StreamConfiguration = this.inputControl.controls.configuration.value;
    if (configuration) {
      const configurationId: string = configuration.id;
      this.inputClusters = this.getClustersByConfigurationId(configurationId);
      this.showInputDetails = true;
      this.onChangeCluster();
    }
  }

  public displayFn(configuration: StreamConfigurationFormItem): string {
    if (!configuration) {
      return '';
    }
    return configuration.name;
  }

  public onSelectInput() {
    const configuration: StreamConfiguration = this.inputControl.controls.configuration.value;
    const configurationId: string = configuration.id;

    const clusters: Cluster[] = this.getClustersByConfigurationId(configurationId);
    this.inputClusters = clusters;

    const firstCluster: Cluster = clusters[0];
    const clusterId: string = firstCluster.id;
    this.inputControl.controls.clusterId.setValue(clusterId);

    this.inputControl.controls.instanceRole.setValue('primary');

    this.inputControl.controls.configuration.setErrors(null);
    this.showInputDetails = true;
    this.onChangeCluster();
  }

  public onTypingInput() {
    this.inputControl.controls.configuration.setErrors({incorrect: true});
    this.showInputDetails = false;
  }

  public onInputCancelClick(event: Event) {
    event.stopPropagation();
    this.inputControl.controls.streamId.setValue(null);
    this.inputControl.controls.configuration.setValue(null);
    this.inputControl.controls.clusterId.setValue(null);
    this.inputControl.controls.instanceRole.setValue(null);
    this.showInputDetails = false;
  }

  private getStream(): Stream {
    const configuration: StreamConfiguration = this.inputControl.controls.configuration.value;
    const streams: Stream[] = configuration.streams;

    const clusterId: string = this.inputControl.controls.clusterId.value;
    const cluster: Cluster = this.inputClusters.find((cluster: Cluster): boolean => cluster.id === clusterId);

    const instances: ClusterInstance[] = cluster.instances;
    const instanceIds: string[] = instances.map((instance: ClusterInstance): string => instance.id);

    const clusterStreams: Stream[] = streams.filter((stream: Stream): boolean => instanceIds.includes(stream.instanceId));

    const instanceRole: InstanceRole = this.inputControl.controls.instanceRole.value;
    return clusterStreams.find((stream: Stream): boolean => stream.encoderRole === instanceRole);
  }

  public onChangeCluster(): void {
    const clusterId: string = this.inputControl.controls.clusterId.value;
    const currentCluster: Cluster = this.inputClusters
      .find((cluster: Cluster): boolean => cluster.id === clusterId);
    this.currentClusterInstances = currentCluster.instances;

    const stream: Stream = this.getStream();
    const streamId: string = stream.id;
    this.inputControl.controls.streamId.setValue(streamId);

    const instanceId: string = stream.instanceId;
    this.currentInstance = this.currentClusterInstances
      .find((instance: Instance): boolean => instance.id === instanceId);
  }

  public isBlocked(configuration: StreamConfigurationFormItem): boolean {
    return !!configuration.blockReason;
  }

  private filterInputsRecord(value: string): StreamConfigurationFormItem[] {
    if (!value) {
      return this.streamConfigurations;
    }

    const lowerCaseValue: string = value.toLowerCase();
    return this.streamConfigurations.filter(
      (item: StreamConfigurationFormItem): boolean => item.name.toLowerCase().indexOf(lowerCaseValue) === 0
    );
  }

}
