import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SwitchRecordingModeEvent} from '../../../pages/inputs/interfaces/switch-recording-mode.event.interface';
import {DvrRecord, DvrRecordStatus} from '../../../services/api/dvr/dvr.model';

@Component({
  selector: 'app-switch-recording-btn',
  templateUrl: './switch-recording-btn.component.html',
  styleUrls: ['./switch-recording-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchRecordingBtnComponent implements OnChanges {
  @Input() streamId: string;
  @Input() records: DvrRecord[];
  @Output() switchRecordingModeEvent: EventEmitter<SwitchRecordingModeEvent> = new EventEmitter();

  public status: DvrRecordStatus = 'completed';
  public startedAt: number = Date.now();
  private recordId: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.records && this.records && this.records.length) {
      const records: DvrRecord[] = this.records
        .filter((dvrRecord: DvrRecord): boolean => dvrRecord.streamId === this.streamId);
      const sortedRecords: DvrRecord[] = records
        .sort((a: DvrRecord, b: DvrRecord): number => b.createdAt - a.createdAt);
      const record: DvrRecord = sortedRecords[0];
      if (record) {
        this.recordId = record.id;
        this.status  = record.status;
        this.startedAt = record.start || null;
      }
    }
  }

  public onSwitchRecordingMode(): void {
    const recordId: string = this.recordId;
    switch (this.status) {
      case 'recording':
      case 'starting':
        this.switchRecordingModeEvent.emit({mode: 'stop', recordId});
        break;
      case 'completed':
      case 'failed':
        this.switchRecordingModeEvent.emit({mode: 'start', recordId});
        break;
      case 'waiting':
        this.switchRecordingModeEvent.emit({mode: 'delete', recordId});
        break;
      default:
        console.error('Unknown status: ' + status);
    }
  }

}
