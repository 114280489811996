import { createReducer, on } from '@ngrx/store';
import * as DeleteGridActions from '../actions/delete-grid.actions';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';

export const deleteGridFeatureKey = 'deleteGrid';

export interface State {
  record: Multiview;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(DeleteGridActions.deleteGrid, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(DeleteGridActions.deleteGridSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(DeleteGridActions.deleteGridFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(DeleteGridActions.clearDeleteGridState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

