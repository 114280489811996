import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as UpdateClipActions from '../actions/update-clip.actions';
import {ClipsService} from '../../../services/api/clips/clips.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Clip} from '../../../services/api/clips/clip.model';


@Injectable()
export class UpdateClipEffects {

  // noinspection JSUnusedGlobalSymbols
  updateClip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateClipActions.updateClip),
      concatMap((payload) =>
        this.clipsService.update(payload.id, payload.data).pipe(
          map((data: Clip) => UpdateClipActions.updateClipSuccess({data})),
          catchError((error: any) => of(UpdateClipActions.updateClipFailure({error}))))
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  updateClipSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateClipActions.updateClipSuccess),
      tap(() => this.snackBar.open('Clip successfully updated', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/clips']))
    );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly clipsService: ClipsService
  ) {
  }

}
