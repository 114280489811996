import {ApiService} from '../api.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NdiSignal} from './ndi-input.model';

@Injectable({
  providedIn: 'root'
})
export class NdiSignalsService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getAll(instanceId: string): Observable<NdiSignal[]> {
    return this.apiService.get<NdiSignal[]>(`/ndi-signals/${instanceId}`);
  }
}
