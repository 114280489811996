import { createAction, props } from '@ngrx/store';
import {Destination, DestinationRequest} from '../../../services/api/destinations/destination.model';

export const createDestination = createAction(
  '[CreateDestination] Create Destination',
  props<{ data: DestinationRequest }>()
);

export const createDestinationSuccess = createAction(
  '[CreateDestination]Create Destination Success',
  props<{ data: Destination }>()
);

export const createDestinationFailure = createAction(
  '[CreateDestination] Create Destination Failure',
  props<{ error: any }>()
);

export const clearCreateDestinationState = createAction(
  '[CreateDestination] clear Create Destination State',
);
