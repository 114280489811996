import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, take, tap} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {loadInputs} from '../../../store/inputs/actions/inputs.actions';
import {getInputsLoaded, getInputsLoading, getInputsRecord} from '../../../store/inputs/selectors/inputs.selectors';
import {
  getDestinationsLoaded,
  getDestinationsLoading,
  getDestinationsRecord
} from '../../../store/destinations/selectors/destinations.selectors';
import {InputInterface} from '../../../models/input.interface';
import {Destination} from '../../../services/api/destinations/destination.model';
import {loadDestinations} from '../../../store/destinations/actions/destinations.actions';
import {SelectedEntity, SelectEntityMode} from './components/entity-select-form/entity-select-form.component';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {
  getStreamConfigurationsLoaded,
  getStreamConfigurationsLoading,
  getStreamConfigurationsRecord
} from '../../../store/stream-configurations/load-all/stream-configurations.load-all.selectors';
import {Cluster} from '../../../services/api/clusters/cluster.model';
import {getClustersLoaded, getClustersLoading, getClustersRecord} from '../../../store/clusters/selectors/clusters.selectors';
import {loadStreamConfigurations} from '../../../store/stream-configurations/load-all/stream-configurations.load-all.actions';
import {loadClusters} from '../../../store/clusters/actions/clusters.actions';
import {MultiviewEntity} from '../../../services/api/multiviews/multiview-viewer.model';

@Component({
  selector: 'app-entity-select-form-container',
  template: `
    <app-entity-select-form [mode]="mode"
                            [index]="index"
                            [entityControl]="entityControl"
                            [entity]="entity"
                            [validationRequired]="validationRequired"
                            [inputsRecord]="inputsRecord$ | async"
                            [inputsLoading]="inputsLoading$ | async"
                            [streamConfigurations]="streamConfigurations$ | async"
                            [streamConfigurationsLoading]="streamConfigurationsLoading$ | async"
                            [clusters]="clusters$ | async"
                            [clustersLoading]="clustersLoading$ | async"
                            [destinations]="destinations$ | async"
                            [destinationsLoading]="destinationsLoading$ | async"
                            (changeEntity)="onEntityChange($event)"
                            (mouseEnter)="mouseEnter.emit($event)"></app-entity-select-form>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySelectFormContainerComponent {
  @Input() mode: SelectEntityMode = 'all'; // all, streams, destinations
  @Input() index: number;
  @Input() entityControl;
  @Input() entity: MultiviewEntity;
  @Input() validationRequired: boolean = false;
  @Output() changeEntity: EventEmitter<SelectedEntity> = new EventEmitter<SelectedEntity>();
  @Output() mouseEnter: EventEmitter<any> = new EventEmitter();
  // Inputs
  inputsRecord$: Observable<InputInterface[]>;
  inputsLoading$: Observable<boolean>;
  // Destinations
  destinations$: Observable<Destination[]>;
  destinationsLoading$: Observable<boolean>;
  // Stream Configurations
  public streamConfigurations$: Observable<StreamConfiguration[]>;
  public streamConfigurationsLoading$: Observable<boolean>;

  // Clusters
  public clusters$: Observable<Cluster[]>;
  public clustersLoading$: Observable<boolean>;

  constructor(private store: Store) {
    this.checkInputs();
    this.checkDestinations();
    this.checkStreamConfigurations();
    this.checkClusters();
  }

  private checkInputs(): void {
    this.inputsRecord$ = this.store.select(getInputsRecord);
    this.inputsLoading$ = this.store.select(getInputsLoading);
    combineLatest(
      this.store.pipe(select(getInputsLoaded)),
      this.store.pipe(select(getInputsLoading))
    ).pipe(
      tap(values => {
        if (values.every((val) => val === false)) {
          this.store.dispatch(loadInputs());
        }
      }),
      filter((values: Array<boolean>) => values.every((val) => val === false)),
      take(1)
    ).subscribe();
  }

  private checkStreamConfigurations(): void {
    this.streamConfigurations$ = this.store.select(getStreamConfigurationsRecord);
    this.streamConfigurationsLoading$ = this.store.select(getStreamConfigurationsLoading);
    combineLatest(
      this.store.pipe(select(getStreamConfigurationsLoaded)),
      this.store.pipe(select(getStreamConfigurationsLoading))
    ).pipe(
      tap(values => {
        if (values.every((value: boolean): boolean => value === false)) {
          this.store.dispatch(loadStreamConfigurations({}));
        }
      }),
      filter((values: Array<boolean>): boolean => values
        .every((value: boolean): boolean => value === false)),
      take(1)
    ).subscribe();
  }

  private checkClusters(): void {
    this.clusters$ = this.store.select(getClustersRecord);
    this.clustersLoading$ = this.store.select(getClustersLoading);
    combineLatest(
      this.store.pipe(select(getClustersLoaded)),
      this.store.pipe(select(getClustersLoading))
    ).pipe(
      tap(values => {
        if (values.every((value: boolean): boolean => value === false)) {
          this.store.dispatch(loadClusters());
        }
      }),
      filter((values: Array<boolean>): boolean => values
        .every((value: boolean): boolean => value === false)),
      take(1)
    ).subscribe();
  }

  private checkDestinations(): void {
    this.destinations$ = this.store.select(getDestinationsRecord);
    this.destinationsLoading$ = this.store.select(getDestinationsLoading);
    combineLatest(
      this.store.pipe(select(getDestinationsLoaded)),
      this.store.pipe(select(getDestinationsLoading))
    ).pipe(
      tap(values => {
        if (values.every((val) => val === false)) {
          this.store.dispatch(loadDestinations());
        }
      }),
      filter((values: Array<boolean>) => values.every((val) => val === false)),
      take(1)
    ).subscribe();
  }

  public onEntityChange(entity: SelectedEntity): void {
    this.changeEntity.emit(entity);
  }
}
