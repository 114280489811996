import { createReducer, on } from '@ngrx/store';
import {NdiSignal} from '../../services/api/ndi-signals/ndi-input.model';
import {clearNdiSignalsState, loadNdiSignals, loadNdiSignalsFailure, loadNdiSignalsSuccess} from './ndi-singnals.actions';


export interface State {
  record: NdiSignal[] | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const loadNdiSignalsReducer = createReducer(
  initialState,
  on(loadNdiSignals, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(loadNdiSignalsSuccess, (state: State, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(loadNdiSignalsFailure, (state: State, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(clearNdiSignalsState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  }))
);
