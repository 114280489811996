import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getOrganizations(): Observable<any> {
    return this.apiService.get(`/organizations/all`);
  }

  public getCurrentOrganization(): Observable<any> {
    return this.apiService.get(`/organizations/current`);
  }

  public getOrganization(organizationId: number): Observable<any> {
    return this.apiService.get(`/organizations/${organizationId}`);
  }

  public createOrganization(data): Observable<any> {
    return this.apiService.post(`/organizations`, data);
  }

  public updateOrganization(data): Observable<any> {
    return this.apiService.put(`/organizations/${data.id}`, data);
  }

  public changeOrganization(data): Observable<any> {
    return this.apiService.put(`/auth/organizations/change`, data);
  }

  public deleteOrganization(organizationId: number): Observable<any> {
    return this.apiService.delete(`/organizations/${organizationId}`);
  }

  public validateDns(dns: string) {
    return this.apiService.get(`/organizations/dns/${dns}`);
  }
}
