import { createAction, props } from '@ngrx/store';
import {Multiview, MultiviewRequest} from '../../../services/api/multiviews/multiview-viewer.model';

export const updateGrid = createAction(
  '[UpdateGrid] Load Update Grids',
  props<{ data: { id: string, data: MultiviewRequest } }>()
);

export const updateGridSuccess = createAction(
  '[UpdateGrid] Update Grid Success',
  props<{ data: Multiview }>()
);

export const updateGridFailure = createAction(
  '[UpdateGrid] Update Grid Failure',
  props<{ error: any }>()
);

export const clearUpdateGridState = createAction(
  '[UpdateGrid] clear Update Grid State',
);
