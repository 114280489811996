import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import * as GridsActions from '../actions/grids.actions';
import {MultiviewsService} from '../../../services/api/multiviews/multiviews.service';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';


@Injectable()
export class GridsEffects {

  // noinspection JSUnusedGlobalSymbols
  loadMultiviews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GridsActions.loadGrids),
      concatMap(() =>
        this.multiviewsService.getAll()
          .pipe(
            map((data: Multiview[]) => GridsActions.loadGridsSuccess({data})),
            catchError((error: any) => of(GridsActions.loadGridsFailure({error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly multiviewsService: MultiviewsService
  ) {
  }

}
