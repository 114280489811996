import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DestinationSrtTargetConfiguration} from '../../../services/api/destinations/destination.model';

export interface DestinationSrtCallerForm {
  address: FormControl<string | null>;
  port: FormControl<number | null>;
  latency: FormControl<number | null>;
  passphrase: FormControl<string | null>;
  srtStreamId: FormControl<string | null>;
  isEncrypted: FormControl<boolean>;
}

@Component({
  selector: 'app-destination-srt-caller-form',
  templateUrl: './destination-srt-caller-form.component.html',
  styleUrls: ['./destination-srt-caller-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationSrtCallerFormComponent implements OnChanges, OnDestroy {
  @Input() destinationControl: FormGroup<DestinationSrtCallerForm>;
  @Input() destination: DestinationSrtTargetConfiguration;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['destinationControl'] && this.destinationControl) {
      if (this.destination) {
        this.createControls(this.destination);
      } else {
        this.createControls();
      }
    }
  }

  public onChangeEncryption(): void {
    const isEncrypted: boolean = this.destinationControl.controls.isEncrypted.value;
    if (!isEncrypted) {
      this.destinationControl.controls.passphrase.setValue(null);
      this.destinationControl.controls.passphrase.disable();
    } else {
      this.destinationControl.controls.passphrase.enable();
    }
  }

  public onPasteSrtAddress(): void {
    setTimeout(() => {
      let value: string = this.destinationControl.controls.address.value;
      if (value.indexOf(':/') !== -1) {
        value = value.slice(value.indexOf(':/') + 3);
      }
      const addressParts: string[] = value.split(':');
      const address: string = addressParts[0];
      const port: string = addressParts[1];
      this.destinationControl.controls.address.setValue(address);
      if (port) {
        const portNum: number = parseInt(port, 10);
        this.destinationControl.controls.port.setValue(portNum);
      }
    });
  }

  public ngOnDestroy(): void {
    this.removeControls();
  }

  private createControls(destination: DestinationSrtTargetConfiguration = {
    address: null,
    port: null,
    passphrase: null,
    latency: 200,
    srtStreamId: null
  }) {
    const isEncrypted: boolean = !!destination.passphrase;
    this.destinationControl.addControl('address', new FormControl(destination.address, Validators.required));
    this.destinationControl.addControl('port', new FormControl(destination.port, Validators.required));
    this.destinationControl.addControl('srtStreamId', new FormControl(destination.srtStreamId));
    this.destinationControl.addControl('isEncrypted', new FormControl(isEncrypted));
    this.destinationControl.addControl('passphrase', new FormControl(destination.passphrase,
      [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(79)
      ]));
    this.destinationControl.addControl('latency', new FormControl(destination.latency,
      [
        Validators.required,
        Validators.min(120),
        Validators.max(4000)
      ]));

    this.onChangeEncryption();
  }

  private removeControls(): void {
    this.destinationControl.removeControl('address');
    this.destinationControl.removeControl('port');
    this.destinationControl.removeControl('isEncrypted');
    this.destinationControl.removeControl('passphrase');
    this.destinationControl.removeControl('latency');
  }

}
