import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DestinationsActions from '../actions/destinations.actions';
import { DestinationsService } from '../../../services/api/destinations/destinations.service';
import {Destination} from '../../../services/api/destinations/destination.model';



@Injectable()
export class DestinationsEffects {

  // noinspection JSUnusedGlobalSymbols
  loadDestinations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DestinationsActions.loadDestinations),
      concatMap(() =>
        this.destinationsService.getDestinations()
          .pipe(
            map((data: Destination[]) => DestinationsActions.loadDestinationsSuccess({ data })),
            catchError(error => of(DestinationsActions.loadDestinationsFailure({ error })))
          )
      )
    );
  });



  constructor(
    private readonly actions$: Actions,
    private readonly destinationsService: DestinationsService
  ) {}

}
