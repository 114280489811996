import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import * as ValidateShortnameActions from '../actions/validate-shortname.actions';
import {MultiviewsService} from '../../../services/api/multiviews/multiviews.service';


@Injectable()
export class ValidateShortnameEffects {

  // noinspection JSUnusedGlobalSymbols
  validateShortname$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValidateShortnameActions.validateShortname),
      concatMap((payload) =>
        this.multiviewsService.validateShortname(payload.data)
          .pipe(
            map(data => ValidateShortnameActions.validateShortnameSuccess({data})),
            catchError(error => of(ValidateShortnameActions.validateShortnameFailure({error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly multiviewsService: MultiviewsService
  ) {
  }

}
