import { createAction, props } from '@ngrx/store';
import {Destination} from '../../../services/api/destinations/destination.model';

export const loadDestination = createAction(
  '[Destination] Load Destination',
  props<{ data: string }>()
);

export const loadDestinationSuccess = createAction(
  '[Destination] Load Destination Success',
  props<{ data: Destination }>()
);

export const loadDestinationFailure = createAction(
  '[Destination] Load Destination Failure',
  props<{ error: any }>()
);

export const clearDestinationState = createAction(
  '[Destination] clear Destination State',
);
