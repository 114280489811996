import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as DeleteGridActions from '../actions/delete-grid.actions';
import * as GridsActions from '../../grids/actions/grids.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MultiviewsService} from '../../../services/api/multiviews/multiviews.service';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';


@Injectable()
export class DeleteGridEffects {

  // noinspection JSUnusedGlobalSymbols
  deleteMultiview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteGridActions.deleteGrid),
      concatMap((payload: {data: string}) =>
        this.multiviewsService.delete(payload.data)
          .pipe(
            map((data: Multiview) => DeleteGridActions.deleteGridSuccess({data})),
            catchError(error => of(DeleteGridActions.deleteGridFailure({error})))
          )
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  deleteMultiviewSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteGridActions.deleteGridSuccess),
      tap(() => this.snackBar.open('Multiview successfully deleted', 'Success', {duration: 3000})),
      map(() => GridsActions.loadGrids())
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly multiviewsService: MultiviewsService
  ) {
  }

}
