import { createAction, props } from '@ngrx/store';
import {Clip} from '../../../services/api/clips/clip.model';

export const loadClips = createAction(
  '[Clips] Load Clips'
);

export const loadClipsSuccess = createAction(
  '[Clips] Load Clips Success',
  props<{ data: Clip[] }>()
);

export const loadClipsFailure = createAction(
  '[Clips] Load Clips Failure',
  props<{ error: any }>()
);

export const updateClipStatus = createAction(
  '[Clips] Update Clips Status',
  props<{ data: Clip[] }>()
);

export const clearClipsState = createAction(
  '[Clips] clear Clips State',
);
