import { createAction, props } from '@ngrx/store';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';

export const loadGrids = createAction(
  '[Grids] Load Grids'
);

export const loadGridsSuccess = createAction(
  '[Grids] Load Grids Success',
  props<{ data: Multiview[] }>()
);

export const loadGridsFailure = createAction(
  '[Grids] Load Grids Failure',
  props<{ error: any }>()
);

export const clearGridsState = createAction(
  '[Grids] clear Grids State',
);
