import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {Cluster, ClusterInstance} from '../../../../../services/api/clusters/cluster.model';
import {map, startWith} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InstanceRole} from '../../../../../services/api/streams/streams.service';
import {SelectedEntity} from '../entity-select-form/entity-select-form.component';
import {Stream, StreamConfiguration} from '../../../../../services/api/stream-configurations/stream-configuration.model';
import {MultiviewEntity} from '../../../../../services/api/multiviews/multiview-viewer.model';

interface InputSelectForm {
  type: FormControl<string>;
  order: FormControl<number>;
  entityId: FormControl<string | null>;
  clusterId: FormControl<string | null>;
  role: FormControl<InstanceRole | null>;
  currentEntity: FormControl<StreamConfiguration | null>;
}

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSelectComponent implements OnChanges {
  @Input() entityControl: FormGroup<InputSelectForm>;
  @Input() entity: MultiviewEntity | null = null;
  @Input() index: number;
  @Input() validationRequired: boolean;
  @Input() streamConfigurations: StreamConfiguration[] | null = null;
  @Input() streamConfigurationsLoading: boolean = true;
  @Input() clusters: Cluster[] | null = null;
  @Input() clustersLoading: boolean = true;

  @Output() public changeEntity: EventEmitter<SelectedEntity> = new EventEmitter<SelectedEntity>();
  @Output() mouseEnter: EventEmitter<any> = new EventEmitter();

  public showInputDetails: boolean = false;
  public filteredOptions: Observable<StreamConfiguration[]>;
  public configurationClusters: Cluster[] = [];
  public currentClusterInstances: ClusterInstance[] = [];
  public currentInstance: ClusterInstance;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['entityControl'] && this.entityControl) {
      this.entityControl.addControl('type', new FormControl('stream'));
      this.entityControl.addControl('order', new FormControl(this.index));
      this.entityControl.addControl('entityId', new FormControl(null));
      this.entityControl.addControl('clusterId', new FormControl(null));
      this.entityControl.addControl('role', new FormControl(null));
      this.entityControl.addControl('currentEntity', new FormControl(null));
      this.setValidators();
    }

    if (this.streamConfigurations) {
      this.filteredOptions = this.entityControl.controls.currentEntity.valueChanges
        .pipe(
          startWith(''),
          map((value: any): string => typeof value === 'string' ? value : value.name),
          map((name: string): StreamConfiguration[] => name ? this._filter(name) : this.streamConfigurations.slice())
        );
    }

    if ((changes['entity'] || changes['streamConfigurations']) && this.entity && this.streamConfigurations) {
      const streamId: string = this.entity.streamId;
      const stream: Stream | undefined = this.getStreamById(streamId);

      if (stream) {
        const configuration: StreamConfiguration | undefined = this.getConfigurationByStreamId(streamId);
        const instanceId: string = stream.instanceId;
        const instanceRole: InstanceRole = stream.encoderRole;

        this.entityControl.controls.currentEntity.setValue(configuration);
        this.entityControl.controls.entityId.setValue(streamId);

        const cluster: Cluster = this.getClusterByInstanceId(instanceId);
        const clusterId: string = cluster.id;
        this.entityControl.controls.clusterId.setValue(clusterId);

        this.entityControl.controls.role.setValue(instanceRole);
      }
    }

    if (this.entityControl.controls.currentEntity.value) {
      const configuration: StreamConfiguration = this.entityControl.controls.currentEntity.value;
      const configurationId: string = configuration.id;

      this.configurationClusters = this.getClustersByConfigurationId(configurationId);
      this.showInputDetails = true;
      this.onChangeCluster();
    }
  }

  public displayFn(configuration: StreamConfiguration): string {
    if (!configuration) {
      return '';
    }

    return configuration.name;
  }

  public onSelectInput(): void {
    const control: FormControl<StreamConfiguration | null> = this.entityControl.controls.currentEntity;
    const configuration: StreamConfiguration = control.value;
    const configurationId: string = configuration.id;

    this.configurationClusters = this.getClustersByConfigurationId(configurationId);
    const currentCluster: Cluster | undefined = this.configurationClusters[0];
    const clusterId: string = currentCluster.id;
    this.entityControl.controls.clusterId.setValue(clusterId);

    const instanceRole: InstanceRole = 'primary';
    this.entityControl.controls.role.setValue(instanceRole);

    const stream: Stream = this.getStream();
    const streamId: string = stream.id;
    this.entityControl.controls.entityId.setValue(streamId);

    control.setErrors(null);
    this.showInputDetails = true;

    this.emitChangeEntity();
    this.onChangeCluster();
  }

  public onTypingInput() {
    this.entityControl.controls.currentEntity.setErrors({incorrect: true});
    this.showInputDetails = false;
  }

  public onInputCancelClick(event: Event) {
    event.stopPropagation();
    this.entityControl.controls.entityId.setValue(null);
    this.entityControl.controls.clusterId.setValue(null);
    this.entityControl.controls.role.setValue(null);
    this.entityControl.controls.currentEntity.setValue(null);
    this.showInputDetails = false;
  }

  public onChangeCluster(): void {
    if (this.configurationClusters && this.configurationClusters.length > 0) {
      const clusterId: string = this.entityControl.controls.clusterId.value;
      const currentCluster: Cluster | undefined = this.configurationClusters.find((cluster: Cluster): boolean => cluster.id === clusterId);
      if (currentCluster) {
        this.currentClusterInstances = currentCluster.instances;

        const streamId: string = this.entityControl.controls.entityId.value;
        const stream: Stream | undefined = this.getStreamById(streamId);
        const instanceId: string = stream.instanceId;
        this.currentInstance = this.getInstanceById(instanceId);
        this.emitChangeEntity();
      }
    }
  }

  private getConfigurationByStreamId(streamId: string): StreamConfiguration | undefined {
    return this.streamConfigurations.find((configuration: StreamConfiguration): boolean =>
      configuration.streams.some((stream: Stream): boolean => stream.id === streamId));
  }

  private getStreamById(streamId: string): Stream | undefined {
    const configuration: StreamConfiguration | undefined = this.getConfigurationByStreamId(streamId);
    if (!configuration) {
      return undefined;
    }
    return configuration.streams.find((stream: Stream): boolean => stream.id === streamId);
  }

  private getClusterByInstanceId(instanceId: string): Cluster | undefined {
    return this.clusters
      .find((cluster: Cluster): boolean => cluster.instances
        .some((instance: ClusterInstance): boolean => instance.id === instanceId)
      );
  }

  private getInstanceById(instanceId: string): ClusterInstance | undefined {
    const cluster: Cluster | undefined = this.getClusterByInstanceId(instanceId);
    if (!cluster) {
      return undefined;
    }

    return cluster.instances.find((instance: ClusterInstance): boolean => instance.id === instanceId);
  }

  private getClustersByConfigurationId(configurationId: string): Cluster[] {
    const configuration: StreamConfiguration | undefined = this.streamConfigurations
      .find((config: StreamConfiguration): boolean => config.id === configurationId);
    if (!configuration) {
      return [];
    }

    const streams: Stream[] = configuration.streams;
    const instanceIds: string[] = streams.map((stream: Stream): string => stream.instanceId);

    return this.clusters.filter((cluster: Cluster): boolean =>
      cluster.instances.some((instance: ClusterInstance): boolean => instanceIds.includes(instance.id))
    );
  }

  private setValidators(): void {
    if (this.validationRequired) {
      this.entityControl.controls.currentEntity.setValidators([Validators.required]);
    }
  }

  private getStream(): Stream {
    const configuration: StreamConfiguration = this.entityControl.controls.currentEntity.value;
    const clusterId: string = this.entityControl.controls.clusterId.value;
    const instanceRole: InstanceRole = this.entityControl.controls.role.value;

    const streams: Stream[] = configuration.streams;
    const instanceIds: string[] = streams.map((stream: Stream): string => stream.instanceId);

    const clusters: Cluster[] = this.clusters.filter((cluster: Cluster): boolean =>
      cluster.instances.some((instance: ClusterInstance): boolean => instanceIds.includes(instance.id))
    );
    const cluster: Cluster | undefined = clusters.find((cluster: Cluster): boolean => cluster.id === clusterId);
    const clusterInstanceIds: string[] = cluster.instances.map((instance: ClusterInstance): string => instance.id);

    const clusterStreams: Stream[] = streams.filter((stream: Stream): boolean => clusterInstanceIds.includes(stream.instanceId));

    return clusterStreams.find((stream: Stream): boolean => stream.encoderRole === instanceRole);
  }

  private _filter(name: string): StreamConfiguration[] {
    const filterValue: string = name.toLowerCase();
    return this.streamConfigurations.filter((option: StreamConfiguration): boolean => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private emitChangeEntity(): void {
    const streamId: string = this.entityControl.controls.entityId.value;
    const clusterId: string = this.entityControl.controls.clusterId.value;
    const instanceRole: InstanceRole = this.entityControl.controls.role.value;

    const selectedEntity: SelectedEntity = {
      type: 'stream',
      entityId: streamId,
      inputId: streamId,
      clusterId: clusterId,
      instanceRole: instanceRole
    };
    this.changeEntity.emit(selectedEntity);
  }

}
