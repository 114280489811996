import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as GridActions from '../actions/grid.actions';
import {MultiviewsService} from '../../../services/api/multiviews/multiviews.service';
import {Grid} from '../../../models/grid.interface';



@Injectable()
export class GridEffects {

  // noinspection JSUnusedGlobalSymbols
  loadMultiview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GridActions.loadGrid),
      concatMap((payload) =>
        this.multiviewsService.getMultiview(payload.data)
          .pipe(
            map((data: Grid | any) => {
              const isWrapped: boolean = !!data?.data || false;
              if (isWrapped) {
                return GridActions.loadGridSuccess({data: data.data});
              }

              return GridActions.loadGridSuccess({data: data});
            }),
            catchError(error => of(GridActions.loadGridFailure({ error })))
          )
      )
    );
  });



  constructor(
    private actions$: Actions,
    private readonly multiviewsService: MultiviewsService
  ) {}

}
