import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as CreateGridActions from '../actions/create-grid.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {MultiviewsService} from '../../../services/api/multiviews/multiviews.service';


@Injectable()
export class CreateGridEffects {

  // noinspection JSUnusedGlobalSymbols
  createMultiview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateGridActions.createGrid),
      concatMap((payload) =>
        this.multiviewsService.create(payload.data)
          .pipe(
            map(data => CreateGridActions.createGridSuccess({data})),
            catchError(error => of(CreateGridActions.createGridFailure({error})))
          )
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  createMultiviewSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateGridActions.createGridSuccess),
      tap(() => this.snackBar.open('Multiview successfully created', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/multiview']))
    );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly multiviewsService: MultiviewsService
  ) {
  }

}
