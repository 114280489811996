import { createAction, props } from '@ngrx/store';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';

export const deleteGrid = createAction(
  '[DeleteGrid] Delete Grid',
  props<{ data: string }>()
);

export const deleteGridSuccess = createAction(
  '[DeleteGrid] Delete Grid Success',
  props<{ data: Multiview }>()
);

export const deleteGridFailure = createAction(
  '[DeleteGrid] Delete Grid Failure',
  props<{ error: any }>()
);

export const clearDeleteGridState = createAction(
  '[DeleteGrid] clear Delete Grid State',
);
