import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUpdeteGrid from '../reducers/update-grid.reducer';

export const selectUpdeteGridState = createFeatureSelector<fromUpdeteGrid.State>(
  fromUpdeteGrid.updateGridFeatureKey
);

export const getUpdeteGridRecord = createSelector(
  selectUpdeteGridState,
  (state) => state.record
);

export const getUpdeteGridLoaded = createSelector(
  selectUpdeteGridState,
  (state) => state.loaded
);

export const getUpdeteGridLoading = createSelector(
  selectUpdeteGridState,
  (state) => state.loading
);

export const getUpdeteGridError = createSelector(
  selectUpdeteGridState,
  (state) => state.error
);
