import { createAction, props } from '@ngrx/store';
import {NdiSignal} from '../../services/api/ndi-signals/ndi-input.model';

export const loadNdiSignals = createAction(
  '[NdiSignals] Load NDI Signals',
  props<{ data: string }>()
);

export const loadNdiSignalsSuccess = createAction(
  '[NdiSignals] Load NDI Signals Success',
  props<{ data: NdiSignal[] }>()
);

export const loadNdiSignalsFailure = createAction(
  '[NdiSignals] Load NDI Signals Failure',
  props<{ error: any }>()
);

export const clearNdiSignalsState = createAction(
  '[NdiSignals] clear NDI Signals State',
);
