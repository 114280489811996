import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as CutVideoActions from '../actions/cut-video.actions';
import {ClipsService} from '../../../services/api/clips/clips.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Clip} from '../../../services/api/clips/clip.model';


@Injectable()
export class CutVideoEffects {

  // noinspection JSUnusedGlobalSymbols
  cutVideo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CutVideoActions.cutVideo),
      concatMap((payload) =>
        this.clipsService.create(payload.data)
          .pipe(
            map((data: Clip) => CutVideoActions.cutVideoSuccess({data})),
            catchError((error: any) => of(CutVideoActions.cutVideoFailure({error})))
          )
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  cutVideoSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CutVideoActions.cutVideoSuccess),
      tap(() => this.snackBar.open('Clip successfully created', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/clips']))
    );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly clipsService: ClipsService
  ) {
  }

}
