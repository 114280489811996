import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UpdateDestinationActions from '../actions/update-destination.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DestinationsService } from '../../../services/api/destinations/destinations.service';
import { InputsTableService } from '../../../pages/inputs/services/inputs-table.service';



@Injectable()
export class UpdateDestinationEffects {

  updateDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateDestinationActions.updateDestination),
      concatMap((payload) =>
        this.destinationsService.update(payload.data)
          .pipe(
            map(data => UpdateDestinationActions.updateDestinationSuccess({ data })),
            catchError(error => of(UpdateDestinationActions.updateDestinationFailure({ error })))
          )
      )
    );
  });

  updateDestinationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateDestinationActions.updateDestinationSuccess),
      tap(() => {
        const config = this.inputsTableService.getTableConfig();
        this.snackBar.open('Destination successfully updated', 'Success', {duration: 3000});
        if (config) {
          this.router.navigate(['/streams']);
        } else {
          this.router.navigate(['/destinations']);
        }
      })
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private router: Router,
              private inputsTableService: InputsTableService,
              private destinationsService: DestinationsService) {}

}
