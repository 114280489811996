import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as CreateDestinationActions from '../actions/create-destination.actions';
import {DestinationsService} from '../../../services/api/destinations/destinations.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {InputsTableService} from '../../../pages/inputs/services/inputs-table.service';
import {Destination} from '../../../services/api/destinations/destination.model';
import {InputsTableConfig} from '../../../models/input.interface';


@Injectable()
export class CreateDestinationEffects {

  // noinspection JSUnusedGlobalSymbols
  createDestination$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CreateDestinationActions.createDestination),
        concatMap((payload) =>
          this.destinationsService.create(payload.data)
            .pipe(
              map((data: Destination) => CreateDestinationActions.createDestinationSuccess({data})),
              catchError((error: any) => of(CreateDestinationActions.createDestinationFailure({error})))
            )
        )
      );
  });

  // noinspection JSUnusedGlobalSymbols
  createDestinationSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CreateDestinationActions.createDestinationSuccess),
        tap(() => {
          const config: InputsTableConfig = this.inputsTableService.getTableConfig();
          this.snackBar.open('Destination successfully created', 'Success', {duration: 3000});
          if (config) {
            this.router.navigate(['/streams']);
          } else {
            this.router.navigate(['/destinations']);
          }
        })
      );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly inputsTableService: InputsTableService,
    private readonly destinationsService: DestinationsService
  ) {
  }

}
