import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRecords from '../reducers/records.reducer';
import {DvrRecord} from '../../../services/api/dvr/dvr.model';
import {State} from '../reducers/records.reducer';

export const selectRecordsState = createFeatureSelector<fromRecords.State>(
  fromRecords.recordsFeatureKey
);

export const getRecords = createSelector(
  selectRecordsState,
  (state: State): DvrRecord[] => state.record
);

export const getRecordsLoaded = createSelector(
  selectRecordsState,
  (state: State): boolean => state.loaded
);

export const getRecordsLoading = createSelector(
  selectRecordsState,
  (state: State): boolean => state.loading
);

export const getRecordsError = createSelector(
  selectRecordsState,
  (state: State): any => state.error
);
