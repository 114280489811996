import { createReducer, on } from '@ngrx/store';
import * as CreateGridActions from '../actions/create-grid.actions';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';

export const createGridFeatureKey = 'createGrid';

export interface State {
  record: Multiview;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(CreateGridActions.createGrid, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(CreateGridActions.createGridSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(CreateGridActions.createGridFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(CreateGridActions.clearCreateGridState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

