import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as UpdeteGridActions from '../actions/update-grid.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Multiview, MultiviewRequest} from '../../../services/api/multiviews/multiview-viewer.model';
import {MultiviewsService} from '../../../services/api/multiviews/multiviews.service';


@Injectable()
export class UpdateGridEffects {

  // noinspection JSUnusedGlobalSymbols
  updateGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdeteGridActions.updateGrid),
      concatMap((payload: { data: { id: string, data: MultiviewRequest } }) => {
          const id: string = payload.data.id;
          const data: MultiviewRequest = payload.data.data;
          return this.multiviewsService.update(id, data)
            .pipe(
              map((data: Multiview) => UpdeteGridActions.updateGridSuccess({data})),
              catchError((error: any) => of(UpdeteGridActions.updateGridFailure({error})))
            );
        }
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  updateGridSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdeteGridActions.updateGridSuccess),
      tap(() => this.snackBar.open('Multiview successfully updated', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/multiview']))
    );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly multiviewsService: MultiviewsService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {
  }

}
