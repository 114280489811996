import { createAction, props } from '@ngrx/store';
import {StartDvrRecordPayload} from '../../../services/api/dvr/interfaces/start-dvr-record-payload.interface';
import {DvrRecord} from '../../../services/api/dvr/dvr.model';

export const startRecord = createAction(
  '[StartRecord] Start Record',
  props<{ data: StartDvrRecordPayload }>()
);

export const startRecordSuccess = createAction(
  '[StartRecord] Start Record Success',
  props<{ data: DvrRecord }>()
);

export const startRecordFailure = createAction(
  '[StartRecord] Start Record Failure',
  props<{ error: any }>()
);

export const clearStartRecordState = createAction(
  '[Records] clear Start Record State',
);
